<template>
  <div class="animated fadeIn">
    <h4 class="mb-3">
      Просмотр пользователя
    </h4>
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col md="6">
          <b-form-group
            class="form-group-el-select"
            label="Пользователь"
            label-for="user"
          >
            <el-select
              id="user"
              v-model="form.adUser"
              class="form-control"
              name="roles"
              placeholder="Начните вводить данные пользователя AD, не менее 3-х букв"
              clearable
              :loading="loading"
              :disabled="true"
            >
              <el-option
                v-for="item in users"
                :key="item.value.username"
                :label="item.text"
                :value="item.value"
              />
            </el-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            class="form-group-el-select"
            label="Роль"
            label-for="roles"
          >
            <el-select-clearable
              id="roles"
              v-model="form.roles"
              class="form-control"
              name="roles"
              placeholder="Выберите одну или несколько ролей"
              clearable
              :multiple="true"
              :loading="loading"
              :disabled="true"
            >
              <el-option
                v-for="item in roles"
                :key="item.value"
                :label="item.text"
                :value="item.value"
              />
            </el-select-clearable>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          v-if="$store.state.user.ownerId == customerId && $store.getters.isAdmin"
          :href="'/customer-users/id'+userId"
          variant="primary"
          :disabled="loading"
          :class="{'loading' : loading}"
        >
          Редактировать
        </b-button>
      </div>
    </b-form>
    <customer-users-roles-info-table />
  </div>
</template>

<script>
import ElSelectClearable from '../../components/ElSelectClearable.vue'
import notifications from '../../components/mixins/notifications';
import {customersAdUsersList, customersAdUserRead, customersAdUserUpdate} from '../../services/api';
import vSelect from 'vue-select';
import CustomerUsersRolesInfoTable from '@/views/CustomerUsers/CustomerUsersRolesInfoTable';

export default {
  name: 'CustomerUserEdit',
  components: {CustomerUsersRolesInfoTable, vSelect, ElSelectClearable},
  mixins: [notifications],
  props: ['customerId', 'userId'],
  data() {
    return {
      loading: false,
      form: {
        roles: [],
        adUser: null,
      },
      roles: [
        {value: 'ROLE_ADMIN', text: 'Администратор'},
        {value: 'ROLE_MANAGER', text: 'Менеджер'},
        {value: 'ROLE_VIEW', text: 'Просмотр'},
      ],
      users: [],
    };
  },
  watch: {
    '$route'() {
      this.getCustomersAdUserData();
    },
  },
  mounted() {
    this.getCustomersAdUserData();
  },
  methods: {
    async getCustomersAdUserData() {
      this.loading = true;
      const response = await customersAdUserRead(this.customerId, this.userId);
      if (response && response.status === 200) {
        this.users.push({value: response.data.username, text: response.data.fullname + ' (' + response.data.email + ')'});
        this.form.adUser = response.data.username;
        this.form.roles = response.data.roles;
      }
      this.loading = false;
    },
  },
};
</script>

